var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "formGroup" },
      [
        _c("app-textbox", {
          attrs: {
            id: "employeeIdOld",
            type: "hidden",
            value: _vm.$options.employeeId
          }
        }),
        _c("app-dropdownlist", {
          attrs: {
            id: "employeeId",
            placeholder: _vm.$t("employees.employee"),
            dataSource: _vm.employees,
            fields: { text: "fullName", value: "id" }
          },
          model: {
            value: _vm.data.employeeId,
            callback: function($$v) {
              _vm.$set(_vm.data, "employeeId", $$v)
            },
            expression: "data.employeeId"
          }
        }),
        _c("app-dropdownlist-employee-calendar-type-visibility", {
          attrs: {
            id: "visibility",
            placeholder: _vm.$t("calendarTypes.visibility.visibility")
          },
          model: {
            value: _vm.data.visibility,
            callback: function($$v) {
              _vm.$set(_vm.data, "visibility", $$v)
            },
            expression: "data.visibility"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }