var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "formGroup" },
      [
        _c("app-textbox", {
          attrs: {
            id: "officeId",
            type: "hidden",
            value: _vm.officeId.toString()
          }
        }),
        _c("app-textbox", {
          attrs: { id: "roomIdOld", type: "hidden", value: _vm.$options.roomId }
        }),
        _c("app-dropdownlist", {
          attrs: {
            id: "roomId",
            placeholder: _vm.$t("rooms.room"),
            dataSource: _vm.rooms,
            fields: { text: "name", value: "id" }
          },
          model: {
            value: _vm.data.roomId,
            callback: function($$v) {
              _vm.$set(_vm.data, "roomId", $$v)
            },
            expression: "data.roomId"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }